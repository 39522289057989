export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SAVE_LOGIN = "SAVE_LOGIN";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const PUBLISH_STORY = "PUBLISH_STORY";
export const GET_BLOG_STORIES = "GET_BLOG_STORIES";
export const GET_USER_STORIES = "GET_USER_STORIES";
export const GET_NEWS_STORIES = "GET_NEWS_STORIES";
export const GET_NEWS_STORY = "GET_NEWS_STORY";
export const SAVE_BLOG_STORIES = "SAVE_BLOG_STORIES";
export const SAVE_USER_STORIES = "SAVE_USER_STORIES";
export const SAVE_NEWS_STORIES = "SAVE_NEWS_STORIES";
export const SAVE_NEWS_STORY = "SAVE_NEWS_STORY";
export const GET_STORY = "GET_STORY";
export const SAVE_STORY = "SAVE_STORY";
export const EDIT_STORY = "EDIT_STORY";
export const DELETE_STORY = "DELETE_STORY";
export const SET_LOADING = "SET_LOADING";
